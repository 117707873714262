import * as React from "react";

const navigation = {
  solutions: [
    // { name: "Services", href: "/services" },
    // { name: "Process", href: "/process" },
    // { name: "Commerce", href: "#" },
    // { name: "Insights", href: "#" },
  ],
  // support: [
  //   { name: 'Pricing', href: '#' },
  //   { name: 'Documentation', href: '#' },
  //   { name: 'Guides', href: '#' },
  //   { name: 'API Status', href: '#' },
  // ],
  // company: [
    // { name: "About", href: "/about" },
    // { name: "Blog", href: "/blog" },
    // { name: "Jobs", href: "#" },
    // { name: "Press", href: "#" },
    // { name: "Partners", href: "#" },
  // ],
  legal: [
    //   { name: 'Claim', href: '#' },
    { name: "Privacy", href: "/privacy" },
    { name: "Terms", href: "/terms" },
  ],
  social: [
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/hyperfluid-solutions",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
        </svg>
      ),
    },
    {
      name: "GitHub",
      href: "https://github.com/hyperfluid-solutions",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Facebook",
      href: "https://www.facebook.com/hyperfluidsolutions",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

const unusedNewsletter = (email, handleInputChange, handleSubmit) => (<div className="mt-8 xl:mt-0">
<h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
  Subscribe to our newsletter
</h3>
<p className="mt-4 text-base text-gray-500">
  The latest news, articles, and resources, sent to your inbox
  weekly.
</p>
<form
  onSubmit={handleSubmit}
  className="mt-4 sm:flex sm:max-w-md"
>
  <label htmlFor="email-address" className="sr-only">
    Email address
  </label>
  <input
    type="email"
    name="email"
    autoComplete="email"
    value={email}
    onChange={handleInputChange}
    required
    className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-purple-700 focus:border-purple-700 focus:placeholder-gray-400"
    placeholder="Enter your email"
  />
  <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
    <button
      type="submit"
      className="w-full bg-purple-800 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white hover:bg-purple-900 focus:ring-2 focus:ring-offset-2 focus:ring-purple-700"
    >
      Subscribe
    </button>
  </div>
</form>
</div>);

export default class Footer extends React.Component {
  constructor() {
    super();
  
    this.state = {
      email: "",
      formstarted: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleInputChange(event) {
    window.gtag && window.gtag('event', 'newsletter_started');
    if (this.state.formstarted === false) {
      this.setState({
        formstarted: new Date(),
      });
    }
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();

    const { formstarted } = this.state;
    const formData = {
      email: this.state.email,
    }
    fetch("https://api.hyperfluidsolutions.com/v1/subscribe", {
      method: 'POST',
      mode: 'no-cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData)
    }).then(response => {
      console.log(response);
      window.gtag && window.gtag('event', 'newsletter_submitted', {
        value: (formstarted instanceof Date && formstarted.toString() !== 'Invalid Date') ? new Date() - formstarted : -1,
      });
    }).catch((ex) => {
      window.gtag && window.gtag('event', 'newsletter_failed', {
        event_category: 'failure',
        event_label: JSON.stringify(ex),
      });
    });
  }
  render() {
    return (
      <footer className="bg-white" aria-labelledby="footer-heading">
        <h2 className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="grid grid-cols-2 gap-8 xl:col-span-2">
              <div className="md:grid md:grid-cols-1 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Contact
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      {/* <span className="text-base text-gray-500">
                      address
                    </span> */}
                      <span className="text-base text-gray-500 block">
                        Hyperfluid Solutions Corp.
                      </span>
                      <span className="text-base text-gray-500 block">
                        # 348
                      </span>
                      <span className="text-base text-gray-500 block">
                        1735 E Carson St
                      </span>
                      <span className="text-base text-gray-500 block">
                        Pittsburgh, PA, 15203
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-1 md:gap-8">
                {/* <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Company
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.company.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-base text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                    {navigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-base text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div> */}
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Legal
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-base text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* Used to be newsletter */}
          </div>
          <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              Copyright &copy; 2014 - 2025 Hyperfluid Solutions Corp. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
